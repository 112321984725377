import Select from '@paljs/ui/Select';
import { Radio } from '@paljs/ui/Radio';
import { Card, CardBody } from '@paljs/ui/Card';
import { Checkbox } from '@paljs/ui/Checkbox';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import {
  requireCheck,
  resetRequired,
  checkNumber,
  allowOnlyHW,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  ADMIN,
  getPageAccessFromState,
  appendErrorMsg,
  pullDownReset,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import toast, { Toaster } from 'react-hot-toast';

const Input = styled(InputGroup)`
  margin-bottom: 10px;
`;

const SelectField = styled(Select)`
  margin-bottom: 10px;
`;

const ActionBtn = styled(Button)`
  margin-bottom: 10px;
  padding: 4px;
`;

const InputPage = () => {
  const [sortData, setSortData] = useState();
  const [tableFilter, setTableFilter] = useState('');
  const [tableData, setTableData] = useState([]);
  const state = useContext(GlobalStateData);
  const pageAccess = getPageAccessFromState(state);
  const [isSave, setSave] = useState(pageAccess.write === 1);
  useEffect(() => {
    authCheck(state.authData);
    getApi();
  }, []);

  const [sortCode, setSortCode] = useState('');
  const [fabricType, setFabricType] = useState('');
  const [warp, setWarp] = useState('');
  const [weft, setWeft] = useState('');
  const [reed, setReed] = useState('');
  const [pick, setPick] = useState('');
  const [blend1, setBlend1] = useState('');
  const [blendPercent1, setBlendPercent1] = useState('');
  const [blend2, setBlend2] = useState('');
  const [blendPercent2, setBlendPercent2] = useState(0);
  const [blend3, setBlend3] = useState('');
  const [blendPercent3, setBlendPercent3] = useState(0);
  const [greyWidth, setGreyWidth] = useState('');
  const [linearMeter, setLinearMeter] = useState('');
  const [design, setDesign] = useState('');
  const [defualtWeave, setDefaultWeave] = useState([]);
  const [weave, setWeave] = useState('');
  const [published_at, setPublishedAt] = useState('');
  const [pickId, setPickId] = useState('');
  const weaveOptions = [
    {
      label: 'Air Tex',
      value: 'Air Tex',
    },
    {
      label: 'Dobby',
      value: 'Dobby',
    },
    {
      label: 'Drill',
      value: 'Drill',
    },
    {
      label: 'Herring Bone',
      value: 'Herring Bone',
    },
    {
      label: 'Honey Comb',
      value: 'Honey Comb',
    },
    {
      label: 'Interlock',
      value: 'Interlock',
    },
    {
      label: 'Jacquard',
      value: 'Jacquard',
    },
    {
      label: 'Lycra',
      value: 'Lycra',
    },
    {
      label: 'Matt',
      value: 'Matt',
    },
    {
      label: 'Others',
      value: 'Others',
    },
    {
      label: 'Oxford',
      value: 'Oxford',
    },
    {
      label: 'Percale',
      value: 'Percale',
    },
    {
      label: 'Plain',
      value: 'Plain',
    },
    {
      label: 'Rib',
      value: 'Rib',
    },
    {
      label: 'Satin',
      value: 'Satin',
    },
    {
      label: 'Single Jersy',
      value: 'Single Jersy',
    },
    {
      label: 'Stripe',
      value: 'Stripe',
    },
    {
      label: 'Twill',
      value: 'Twill',
    },
    {
      label: 'Waffle',
      value: 'Waffle',
    },
  ];

  const handleWeaveChange = (event) => {
    setWeave(event.value);
    setDefaultWeave({
      label: event.label,
      value: event.value,
    });
    pullDownReset('weave');
  };

  const handleChange = (event) => {
    resetRequired(event);
    allowOnlyHW();
    switch (event.target.name) {
      case 'sortCode':
        setSortCode(event.target.value);
        break;
      case 'fabricType':
        setFabricType(event.target.value);
        break;
      case 'warp':
        setWarp(event.target.value);
        break;
      case 'weft':
        setWeft(event.target.value);
        break;
      case 'reed':
        setReed(event.target.value);
        break;
      case 'pick':
        setPick(event.target.value);
        break;
      case 'blend1':
        setBlend1(event.target.value);
        break;
      case 'blendPercent1':
        setBlendPercent1(event.target.value);
        break;
      case 'blend2':
        setBlend2(event.target.value);
        break;
      case 'blendPercent2':
        setBlendPercent2(event.target.value);
        break;
      case 'blend3':
        setBlend3(event.target.value);
        break;
      case 'blendPercent3':
        setBlendPercent3(event.target.value);
        break;
      case 'greyWidth':
        setGreyWidth(checkNumber(event.target, '3.0'));
        break;
      case 'linearMeter':
        setLinearMeter(event.target.value);
        break;
      case 'published_at':
        setPublishedAt(event.target.value);
        break;
      case 'design':
        setDesign(event.target.value);
        break;
    }
  };

  const clearFormData = () => {
    setSave(pageAccess.write === 1);
    setPickId('');
    setSortCode('');
    setFabricType('');
    setWarp('');
    setWeft('');
    setReed('');
    setPick('');
    setBlend1('');
    setBlendPercent1(0);
    setBlend2('');
    setBlendPercent2(0);
    setBlend3('');
    setBlendPercent3(0);
    setGreyWidth('');
    setLinearMeter('');
    setPublishedAt('');
    setDesign('');
    setDefaultWeave([]);
    setWeave('');
    resetRequired();
  };

  const getApi = () => {
    showLoader();
    axios
      .get(baseUri() + 'sorts?sort[0]=id:desc', {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setSortData(response.data.data);
        if (tableFilter) {
          filterChange(tableFilter, response.data.data);
        } else {
          setTableData(response.data.data);
        }
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const postApi = () => {
    const postData = {
      data: {
        sortCode,
        fabricType,
        warp,
        weft,
        reed,
        pick,
        blend1,
        blendPercent1,
        blend2,
        blendPercent2,
        blend3,
        blendPercent3,
        greyWidth,
        linearMeter,
        weave,
        design,
      },
    };
    let sortCodeValid = allowOnlyHW();
    let validate = requireCheck(postData, sortData, 'sortCode', pickId);
    if (+linearMeter <= 0) {
      const linearMeterEle = document.querySelector('.linearMeter');
      appendErrorMsg(linearMeterEle, linearMeterEle?.parentElement, 'Value must be greater than zero');
      return false;
    }
    if (+blendPercent1 + +blendPercent2 + +blendPercent3 !== 100) {
      validate = false;
      toast.dismiss();
      toast.error('The sum of blend percentages should be 100%');
    }
    if (!validate || !sortCodeValid) {
      return false;
    }
    for (const key in postData['data']) {
      if (postData['data'][key] == '') {
        delete postData['data'][key];
      }
    }
    showLoader();
    if (pickId) {
      axios
        .put(baseUri() + 'sorts' + '/' + pickId, postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    } else {
      axios
        .post(baseUri() + 'sorts', postData, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const editSort = (id) => {
    showLoader();
    clearFormData();
    axios
      .get(baseUri() + 'sorts/' + id, {
        headers: {
          authorization: 'Bearer ' + state.authData,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        response = response.data.data;
        setSave(pageAccess.edit === 1);
        setPickId(response.id);
        setSortCode(response.attributes.sortCode);
        setBlend1(response.attributes.blend1);
        setBlend2(response.attributes.blend2);
        setBlend3(response.attributes.blend3);
        setBlendPercent1(response.attributes.blendPercent1);
        setBlendPercent2(response.attributes.blendPercent2);
        setBlendPercent3(response.attributes.blendPercent3);
        setFabricType(response.attributes.fabricType);
        setGreyWidth(response.attributes.greyWidth);
        setLinearMeter(response.attributes.linearMeter);
        setPick(response.attributes.pick);
        setReed(response.attributes.reed);
        setWarp(response.attributes.warp);
        setWeft(response.attributes.weft);
        if (response.attributes.weave) {
          setWeave(response.attributes.weave);
          setDefaultWeave({ label: response.attributes.weave, value: response.attributes.weave });
        }
        setDesign(response.attributes.design);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        hideLoader();
      })
      .catch((error) => {
        //return  error;
      });
  };

  const deleteSort = (id) => {
    const removeGroup = window.confirm('Would you like to delete this sort?');
    if (removeGroup) {
      showLoader();
      setPickId(id);
      axios
        .delete(baseUri() + 'sorts/' + id, {
          headers: {
            authorization: 'Bearer ' + state.authData,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((response) => {
          clearFormData();
          getApi();
        })
        .catch((error) => {
          //return  error;
        });
    }
  };

  const columns = [
    {
      name: 'Action',
      cell: (row) => (
        <div>
          <ActionBtn
            data-tag="allowRowEvents"
            className="abtn-color"
            onClick={() => {
              editSort(row.id);
            }}
          >
            {' '}
            <EvaIcon name="edit" />{' '}
          </ActionBtn>
          {pageAccess.delete === 1 && (
            <ActionBtn
              data-tag="allowRowEvents"
              className="abtndel-color"
              onClick={() => {
                deleteSort(row.id);
              }}
            >
              {' '}
              <EvaIcon name="trash" />{' '}
            </ActionBtn>
          )}
        </div>
      ),
    },
    {
      name: 'Sort Code',
      selector: (row) => `${row.attributes.sortCode}`,
      sortable: true,
      maxWidth: '30%',
    },
    {
      name: 'Fabric Type',
      selector: (row) => `${row.attributes.fabricType}`,
      sortable: true,
      maxWidth: '20%',
    },
    {
      name: 'Warp',
      selector: (row) => numFormatter(row.attributes.warp),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Weft',
      selector: (row) => numFormatter(row.attributes.weft),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Reed',
      selector: (row) => numFormatter(row.attributes.reed),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Pick',
      selector: (row) => numFormatter(row.attributes.pick),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Blend 1 - %',
      selector: (row) => numFormatter(row.attributes.blend1) + ' - ' + numFormatter(row.attributes.blendPercent1),
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Blend 2 - %',
      selector: (row) => numFormatter(row.attributes.blend2) + ' - ' + numFormatter(row.attributes.blendPercent2),
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Blend 3 - %',
      selector: (row) => numFormatter(row.attributes.blend3) + ' - ' + numFormatter(row.attributes.blendPercent3),
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: 'Grey Width',
      selector: (row) => numFormatter(row.attributes.greyWidth),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Linear Meter/KG',
      selector: (row) => numFormatter(row.attributes.linearMeter),
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Weave',
      selector: (row) => row.attributes.weave,
      sortable: true,
      maxWidth: '5%',
    },
    {
      name: 'Design',
      selector: (row) => row.attributes.design,
      sortable: true,
      maxWidth: '5%',
    },
  ];

  const filterColumns = [
    'sortCode',
    'fabricType',
    'warp',
    'weft',
    'reed',
    'pick',
    'blend1',
    'blendPercent1',
    'blend2',
    'blendPercent2',
    'blend3',
    'blendPercent3',
    'greyWidth',
    'linearMeter',
  ];
  const filterChange = (searchInput, newData = false) => {
    setTableFilter(searchInput);
    let available = [];
    if (newData) {
      available = newData;
    } else {
      available = sortData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };

  return (
    <>
      <SEO title="Sort Master" />
      <Toaster />
      <Row>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header> Sort Master</header>
            <CardBody className="cardbody">
              <Row>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>
                    Sort Code <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="sortCode"
                      placeholder="Sort Code *"
                      onChange={(e) => handleChange(e)}
                      value={sortCode}
                      maxLength="6"
                      className="sortCode restrictHW require"
                    />
                  </Input>
                  <label>
                    Fabric Type <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      name="fabricType"
                      placeholder="Fabric Type *"
                      onChange={(e) => handleChange(e)}
                      value={fabricType}
                      className="fabricType require"
                    />
                  </Input>
                  <label>
                    Warp <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      name="warp"
                      placeholder="Warp *"
                      onChange={(e) => handleChange(e)}
                      value={warp}
                      maxLength="8"
                      className="warp require"
                    />
                  </Input>
                  <label>
                    Weft <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      name="weft"
                      placeholder="Weft *"
                      onChange={(e) => handleChange(e)}
                      value={weft}
                      maxLength="8"
                      className="weft require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>
                    Reed <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      name="reed"
                      placeholder="Reed *"
                      onChange={(e) => handleChange(e)}
                      value={reed}
                      maxLength="6"
                      className="reed require"
                    />
                  </Input>
                  <label>
                    Pick <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      name="pick"
                      placeholder="Pick *"
                      onChange={(e) => handleChange(e)}
                      value={pick}
                      maxLength="6"
                      className="pick require"
                    />
                  </Input>
                  <label>
                    Blend 1 <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      name="blend1"
                      placeholder="Blend 1 *"
                      onChange={(e) => handleChange(e)}
                      value={blend1}
                      maxLength="15"
                      className="blend1 require"
                    />
                  </Input>
                  <label>
                    Blend Percent 1 <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="number"
                      name="blendPercent1"
                      placeholder="Blend Percent 1 *"
                      onChange={(e) => handleChange(e)}
                      value={blendPercent1}
                      className="blendPercent1 require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>Blend 2</label>
                  <Input fullWidth size="Small">
                    <input
                      name="blend2"
                      placeholder="Blend 2"
                      onChange={(e) => handleChange(e)}
                      value={blend2}
                      maxLength="15"
                      className="blend2"
                    />
                  </Input>
                  <label>Blend Percent 2</label>
                  <Input fullWidth size="Small">
                    <input
                      type="number"
                      name="blendPercent2"
                      placeholder="Blend Percent 2"
                      onChange={(e) => handleChange(e)}
                      value={blendPercent2}
                      className="blendPercent2"
                    />
                  </Input>
                  <label>Blend 3</label>
                  <Input fullWidth size="Small">
                    <input
                      name="blend3"
                      placeholder="Blend 3"
                      onChange={(e) => handleChange(e)}
                      value={blend3}
                      maxLength="15"
                      className="blend3"
                    />
                  </Input>
                  <label>Blend Percent 3</label>
                  <Input fullWidth size="Small">
                    <input
                      type="number"
                      name="blendPercent3"
                      placeholder="Blend Percent 3"
                      onChange={(e) => handleChange(e)}
                      value={blendPercent3}
                      className="blendPercent3"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 3 }}>
                  <label>
                    Grey Width Cms<span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      name="greyWidth"
                      placeholder="Grey Width *"
                      onChange={(e) => handleChange(e)}
                      value={greyWidth}
                      maxLength="3"
                      className="greyWidth require"
                    />
                  </Input>
                  <label>
                    Linear Meter/KG <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="number"
                      name="linearMeter"
                      placeholder="Linear Meter/KG *"
                      onChange={(e) => handleChange(e)}
                      value={linearMeter}
                      className="linearMeter require"
                    />
                  </Input>
                  <label>
                    Weave <span className="red">*</span>
                  </label>
                  <SelectField
                    fullWidth
                    size="Small"
                    name="weave"
                    options={weaveOptions}
                    placeholder="weave"
                    onChange={handleWeaveChange}
                    value={defualtWeave}
                    className="weave require"
                    id="weave"
                  />
                  <label>
                    Design <span className="red">*</span>
                  </label>
                  <Input fullWidth size="Small">
                    <input
                      type="text"
                      maxLength={15}
                      name="design"
                      placeholder="Desgin"
                      onChange={handleChange}
                      value={design}
                      className="design require"
                    />
                  </Input>
                </Col>
                <Col breakPoint={{ xs: 12, md: 12 }}>
                  <Row middle="lg" style={{ marginLeft: '1px' }}>
                    <Button
                      status="Success"
                      type="button"
                      disabled={!isSave}
                      className="btnrad"
                      shape=""
                      halfWidth
                      onClick={postApi}
                    >
                      Save
                    </Button>
                    &nbsp; &nbsp;
                    <Button status="danger" type="button" className="btnrad" shape="" halfWidth onClick={clearFormData}>
                      Reset
                    </Button>
                    <div style={{ marginLeft: '10px' }}>If any of the value is not known, please enter 0 (Zero)</div>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col breakPoint={{ xs: 12, md: 12 }}>
          <Card>
            <header>Sort Master</header>
            <CardBody>
              <Input fullWidth size="Small">
                <input
                  type="text"
                  onChange={(e) => filterChange(e.target.value)}
                  placeholder="Filter"
                  name="tableFilter"
                  value={tableFilter}
                />
              </Input>
              <DataTable
                columns={columns}
                data={tableData}
                fixedHeader
                allowOverflow={false}
                overflowY={false}
                overflowX={false}
                wrap={false}
                highlightOnHover={true}
                pagination={true}
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default InputPage;
